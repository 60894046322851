import { useEffect, useState } from "react";
import css from "./ScrollTopButton.module.css"
const ScrollToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scrolled up to a certain amount
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll the page to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div  className={`${css.scrollToTop} ${!isVisible && css.hidden}`}>
                <button onClick={scrollToTop}  className={css.scrollButton}>
                    &#8679; {/* Up arrow symbol */}
                </button>
        </div>
    );
}

export default ScrollToTopButton;