import React from 'react';
import errorImg from "../../assets/errors/404.png"
import css from "./NotFoundPage.module.css"

const NotFoundPage = () => {
    return (
        <>
            <img className={css.img} src={errorImg} alt=""/>
            <div className={`${css.container}`}>
                <img className={css.img} alt=""/>
                <div className={css.filter}/>
            </div>
        </>
    );
};

export default NotFoundPage;