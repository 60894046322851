import React, {useEffect, useLayoutEffect} from 'react';
import {PageHead} from "widgets/PageHead";
import LoadingSpinner from "shared/ui/LoadingSpinner/LoadingSpinner";
import {observer} from "mobx-react";
import BlockContainer from "shared/ui/BlockContainer/BlockContainer";
import {useParams} from "react-router";
import pageStore from "../../entities/page/store/pageStore";
import RenderElements from "../../features/RenderElements/RenderElements";
import {useNavigate} from "react-router-dom";
import {RouterNames} from "../../shared/enums/RouterNames";

const UniversalPage = observer(() => {

    const {currentPage, isLoading} = pageStore;
    const {name} = useParams()
    const navigate = useNavigate()

    useLayoutEffect(() => {
        name && pageStore.changeCurrentPage(name)
    }, []);

    useEffect(() => {
        name && pageStore.changeCurrentPage(name)
    }, [name]);

    if (isLoading) {
        return <LoadingSpinner spinnerWidth={"100px"} spinnerHeight={"100px"} padding={"30%"}/>
    } else {
        if (currentPage.id) {
            return (
                <>
                    {currentPage?.titleImage?.id &&
                        <PageHead title={currentPage?.title} imgId={currentPage?.titleImage?.id}/>
                    }
                    <BlockContainer>
                        <RenderElements elements={pageStore.getRenderElements()}/>
                    </BlockContainer>
                </>
            );
        } else {
            navigate(RouterNames.NOT_FOUND.to)
            return (<></>)
        }

    }
});

export default UniversalPage;