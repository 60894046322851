import React, {FC} from "react";
import css from "./FooterInput.module.scss"
import {Email} from "../../../../../../entities/email";

interface InputProps {
    email: Email
    giveEmail: any
}

const FooterInput: FC<InputProps> = ({email, giveEmail}) => {
    return (
        <div className={css.inputContainer}>
            <input value={email.email}  type="email" className={css.inputMain} placeholder="Email" name="email" id='email' required
            onChange={(e) => giveEmail(e.target.value)}/>
            <label htmlFor="email" className={css.inputLabel}>Email</label>
        </div>
    );
};

export default FooterInput;