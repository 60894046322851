import React, {FC} from 'react';
import css from "./TitleLogo.module.scss";
import logo from "../../../../../../assets/logo/logo.png"
import {observer} from "mobx-react";
import {instituteName} from "../../../../../../shared/enums/Data";
import {languageToggleStore} from "../../../../../../features/LanguageToggle/model/languageToggleStore";
import HTMLReactParser from "html-react-parser";

interface TitleNameProps {
    value: string;
    className: string
}

const TitleLogo: FC<TitleNameProps> = observer(({value, className}) => {

    const {activeLanguage} = languageToggleStore

    return (
        <div className={css.containerTitleLogo}>
            <img className={css.logo} src={logo} alt=""/>
            <div className={className? className : css.titleName}>
            {HTMLReactParser(activeLanguage === "ua" ? instituteName.ua : instituteName.eng)}
            </div>
        </div>
    );
});

export default TitleLogo;