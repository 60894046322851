import React, {FC} from 'react';
import css from "./SearchResultBlock.module.scss"
import {RouterNames} from "../../shared/enums/RouterNames";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import HTMLReactParser from "html-react-parser";

interface SearchResultBlockProps {
    index: number,
    value: any,
    type: string,
    close?: () => void;
}

const SearchResultBlock: FC<SearchResultBlockProps> = observer(({index, value, type, close}) => {
    const inputDate = type === "news"? value.date : value.created_at;
    const formattedDate = new Date(inputDate).toLocaleDateString();

    function goTo () {
        if (close) {
            close()
        }
    }

    return (
        <Link to={`${type === "news" ? RouterNames.NEWS.to + '/' + value.id : `/page/${value.name}`} `} onClick={() => goTo()}
              className={css.searchResultBlock} style={type === 'news' ? {minHeight: '40px'} : {minHeight: "max-content"}}>
            { type === "news" && (
                <span className={css.resultDate}>{formattedDate}</span>
            )}
            <span className={css.resultTitle} ><></> {HTMLReactParser(value.title)}</span>
        </Link>
    );
});

export default SearchResultBlock;