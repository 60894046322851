import React, {FC} from 'react';
import css from "./NavigationFooter.module.scss"
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {RouterNames} from "../../../../../../shared/enums/RouterNames";
import {languageToggleStore} from "../../../../../../features/LanguageToggle/model/languageToggleStore";

interface NavigationElement {
    index: number;
    value: any[];
}

const NavigationFooter: FC<NavigationElement> = observer(({index, value}) => {
    const {activeLanguage} = languageToggleStore

    const titleArray = [
        RouterNames.INSTITUTE,
        RouterNames.STUDY,
        RouterNames.ENTRANTS,
        RouterNames.CONTACTS
    ]

    return (
        <div className={css.navigationContainer}>
            <span className={css.navigationTitle}>{activeLanguage === "ua" ? titleArray[index].titleUA : titleArray[index].titleENG}</span>
            {value.map((el, index) => (
                <Link  key={`${el.titleENG}_${index}`}
                       to={el.to}
                       className={css.navigationElement}>{activeLanguage === "ua" ? el.titleUA : el.titleENG}</Link>
            ))}
        </div>
    );
});

export default NavigationFooter;