import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {observer} from "mobx-react";
import newsDetailedStore from "../../entities/news/store/news-detailed-store";
import {PageHead} from "../../widgets/PageHead";
import NewsDetailedBlock from "../../widgets/NewsDetailedBlock/NewsDetailedBlock";
import LoadingSpinner from "../../shared/ui/LoadingSpinner/LoadingSpinner";
import {RouterNames} from "../../shared/enums/RouterNames";
import {useNavigate} from "react-router-dom";

const NewsDetailedPage = observer(() => {
    const {id} = useParams()
    const {news, isLoading} = newsDetailedStore
    const navigate = useNavigate()

    useEffect(() => {
        id && newsDetailedStore.getNewsById(id)
    }, []);

    useEffect(() => {
        id && newsDetailedStore.getNewsById(id)
    }, [id]);

    if (isLoading) {
        return <LoadingSpinner spinnerWidth={"100px"} spinnerHeight={"100px"} padding={"30%"}/>
    } else {
        if (news.id) {
            return (
                <>
                    {news?.titleImage?.id &&
                        <PageHead title={""} imgId={news.titleImage?.id}/>
                    }
                    <NewsDetailedBlock news={news}/>
                </>
            );
        } else {
            navigate(RouterNames.NOT_FOUND.to)
            return (<></>)
        }

    }
});

export default NewsDetailedPage;