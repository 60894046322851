import React, {FC} from 'react';
import css from "./LinkComponent.module.css";
import HTMLReactParser from "html-react-parser";
import {ImageComponent} from "../../../../../features/ImageComponent";
import {LinkElement} from "../../../../../entities/text-block/model/types";

interface LinkElementsProps {
    link: LinkElement;
    id?: number;
}

const LinkElementTB: FC<LinkElementsProps> = ({link, id}) => {
    return (
        <a target={"_blank"} href={link.pageId ? `/page/${link.pageName}` : link.link} className={css.container}>
            <ImageComponent className={css.image}
                            imgId={link.image?.id}/>
            <div className={css.title}>
                {link.title &&
                    <span className={css.link}>{HTMLReactParser(link.title)}</span>
                }
            </div>
        </a>
    );
};

export default LinkElementTB;