import $api from "../../../shared/api";

class EmailStore {
    static async sendEmail(email: string) {
        try {
            await $api.post(`/email-subs`, {email: email});
        } catch (error) {
            console.error(error);
        }
    }
}

export default EmailStore;