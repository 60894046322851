import React, {FC} from "react";
import css from "./FooterCheckbox.module.scss"
import {Email} from "../../../../../../entities/email";
import {languageToggleStore} from "../../../../../../features/LanguageToggle/model/languageToggleStore";
import {observer} from "mobx-react";

interface FooterCheckboxProps {
    email: Email,
    giveCheck: any
}



const FooterCheckbox: FC<FooterCheckboxProps> = observer(({email, giveCheck}) => {

    const uaLang =  [
        'Я прочитав і приймаю',
        'умови використання',
        'та',
        'Політику конфіденційності',
    ]

    const enLang =  [
        'I have read and accept',
        'the Terms of Use',
        'and',
        'Privacy Policy',
    ]

    const {activeLanguage} = languageToggleStore

    return (
        <label className={css.footerCheckboxContainer}>
            <input checked={email.check} className={css.footerCheckbox} type="checkbox" name="checkbox" onChange={(e) => giveCheck(e.target.value)}/>
            <label>{activeLanguage === "ua" ? uaLang[0] : enLang[0]}
                <label className={css.checkboxText}> {activeLanguage === "ua" ? uaLang[1] : enLang[1]} </label>
                {activeLanguage === "ua" ? uaLang[2] : enLang[2]}
                <label className={css.checkboxText}> {activeLanguage === "ua" ? uaLang[3] : enLang[3]}</label>
            </label>
        </label>
    );
});

export default FooterCheckbox;