import React from 'react';
import {PageHead} from "widgets/PageHead";
import {NewsBlock} from "widgets/NewsBlock";
import {observer} from "mobx-react";

const NewsPage = observer(() => {

    return (
        <>
            <PageHead title={"Новини"} imgId={16245}/>
            <NewsBlock/>
        </>
    );
});

export default NewsPage
