import {action, computed, makeAutoObservable, observable} from "mobx";
import $api from "../../../shared/api";
import {Page} from "../model/types";
import ConvertJSONtoTextBlocks from "../utils/ConvertJSONtoTextBlocks";
import {TextBlock} from "../../text-block";
import {AccordionElement} from "../../accordion-element";

class PageStore {
    @observable currentPage: Page = {} as Page;
    @observable isLoading: boolean = true;
    @observable titleImageIsLoading: boolean = true;
    @observable pages: Page[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    async changeCurrentPage(name: string) {
        try {
            this.currentPage = {} as Page
            this.isLoading = true
            await this.loadPage(name);
            this.isLoading = false
        } catch (error) {
            console.error(error);
        }
    }

    @computed
    getRenderElements() {
        const result: (TextBlock | AccordionElement)[] = [];

        this.currentPage.textBlocks?.forEach((item) => {
            result.push(item);
        });

        this.currentPage.accordionElements?.forEach((item) => {
            result.push(item);
        });

        return result.sort((a, b) => a.orderNumber - b.orderNumber);
    }

    @action
    async loadPage(name: string) {
        try {
            const response = await $api.get(`/pages/name`, {
                params: {name: name},
            });
            const pageData = await ConvertJSONtoTextBlocks(response.data);
            this.currentPage = pageData;
        } catch (error) {
            console.log(error)
        }
    }

    @action
    async loadAllPages() {
        try {
            const response = await $api.get(`/pages`);
            const pages: Page[] = [];

            for (let i = 0; i < response.data.length; i++) {
                const pageData = await ConvertJSONtoTextBlocks(response.data[i]);
                pages.push(pageData);
            }

            this.pages = pages;
        } catch (error) {
            console.error(error);
            window.location.replace('/');
        } finally {
            this.isLoading = false;
        }
    }
}

const pageStore = new PageStore();
export default pageStore;
