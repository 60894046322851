import React, {useEffect, useState} from 'react';
import css from "./LayoutFooterAlter.module.scss"
import {observer} from "mobx-react";
import TitleLogo from "./TitleLogo/TitleLogo"
import FooterInput from "./FooterInput/FooterInput";
import FooterCheckbox from "./FooterCheckbox/FooterCheckbox";
import MiddleTitle from "./MiddleTitle/MiddleTitle";
import {Email} from "entities/email";
import EmailStore from "../../../../../entities/email/store";
import {RouterNames} from "../../../../../shared/enums/RouterNames";
import NavigationAlterFooter from "./NavigationAlterFooter/NavigationAlterFooter";
import {languageToggleStore} from "../../../../../features/LanguageToggle/model/languageToggleStore";
import {version} from "../../../../../app/utils/version";


export const LayoutFooterAlter = observer(() => {

    const {activeLanguage} = languageToggleStore

    const navArray = [
        [
            RouterNames.WHY_MITIT,
            RouterNames.HISTORY,
            RouterNames.SUPERIORS,
            RouterNames.PUBLIC_INFO,
            RouterNames.HEROES
        ],
        [
            RouterNames.EDUCATIONAL_ACTIVITIES,
            RouterNames.METHODICAL_WORK,
            RouterNames.MATERIAL_AND_TECHNICAL_BASE,
            RouterNames.LICENSE_CERTIFICATIONS_ACCREDITATION,
            RouterNames.REGULATORY_FRAMEWORK
        ],
        [
            RouterNames.FULL_TIME_EDUCATION,
            RouterNames.EXTRAMURAL_STUDIES,
            RouterNames.DOCTORAL_STUDIES_ADJUNCT_STUDIES,
            RouterNames.PROGRAM_OF_RESERVE_OFFICERS,
            RouterNames.RECEPTION,
            RouterNames.EXAM_2020
        ],
        [
            RouterNames.RECEPTION_OF_CITIZENS,
            RouterNames.ADDRESS,
            RouterNames.HOTLINE
        ]
    ]

    const [email, setEmail] = useState({} as Email)
    const emailRegex: RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const [status, setStatus] = useState("ПІДПИСАТИСЯ")
    const [error, setError] = useState("")


    useEffect(() => {
        checkLang()
    }, [activeLanguage])


    function checkLang() {
        if (activeLanguage === 'ua') setStatus("ПІДПИСАТИСЯ")
        else setStatus("SUBSCRIBE")
    }

    const giveEmail = (value: string) => setEmail(prevEmail => ({ ...prevEmail, email: value }))
    const giveCheck = (status: boolean) => setEmail(prevEmail => ({ ...prevEmail, check: !email.check }))
    const sendEmail = () => {
        if (email?.email) {
            if (email?.check) {
                const matches: RegExpMatchArray | null = email?.email.match(emailRegex);
                if (matches) {
                    EmailStore.sendEmail(email.email).then(() => {
                        setError("")
                        if (activeLanguage === 'ua') setStatus("ДЯКУЮЄМО ЗА ПІДПИСКУ")
                        else setStatus("THANK YOU FOR SUBSCRIBING")
                        setTimeout(() => {
                            checkLang()
                            giveEmail("")
                            giveCheck(false)
                        }, 2000)
                    })
                }else setError("Ви вказали некоректну пошту")
            }else setError("Прийміть умови використання та Політику конфіденційності")
        }else setError("Ви не вказали свою пошту")
    }

    const uaLang = 'Будь в курсі подій! Підпишись на розсилку новин'

    const enLang =  'Stay up to date! Subscribe to the newsletter'



    return (
        <div>
            <div className={css.footerAlterContainer}>
                <div className={css.footerAlterLeft}>
                    <div>
                        <h2>{activeLanguage === 'ua' ? uaLang : enLang}</h2>
                        <FooterInput email={email} giveEmail={giveEmail}/>
                    </div>
                    <FooterCheckbox email={email} giveCheck={giveCheck}/>
                    {error?
                        <span style={{color:"red"}}>{error}</span>
                        :
                        null
                    }
                    <button className={css.footerButton} onClick={() => sendEmail()}>{status}</button>
                </div>
                <div className={css.footerAlterRight}>
                    <TitleLogo/>
                    <MiddleTitle/>
                    <div className={css.footerNavigationContainer}>
                        {navArray.map((value, index) => (
                            <NavigationAlterFooter key={index} index={index} value={value}/>
                        ))}
                    </div>
                </div>
            </div>
            <div className={css.footerGreenContainer}>
                <div>© Військовий інститут телекомунікацій та інформатизації, 2009-2024.</div>
                <div>{version}</div>
            </div>
        </div>
    )
})

