import { SubLink} from "../../widgets/WrapperParts/Header/model/types";

export const RouterNames = {

    HOME: {to: "/", name: "home", titleUA: "Головна", titleENG: "Main"} ,
    INSTITUTE: {to: "/institute", name: "institute", titleUA: "Інститут", titleENG: "Institute"},
    ENTRANTS: {to: "/page/entrants", name: "entrants", titleUA: "Вступникам", titleENG: "Entrances"},
    STUDY: {to: "/page/study", name: "study", titleUA: "Навчання", titleENG: "Study"},
    CONTACTS: {to: "/page/contacts", name: "contacts", titleUA: "Контакти", titleENG: "Contacts"},

    NEWS: {to: "/news", name: "news", titleUA: "Новини", titleENG: "News"},
    NOT_FOUND: {to: "/404", name: "not_found", titleUA: "Не знайдено", titleENG: "Not Found"},
    SPECIALITIES: {to: "/page/specialities", name: "specialities", titleUA: "Спеціальності", titleENG: "Specialities"},
    SCIENCE: {to: "/page/science", name: "science", titleUA: "Наука", titleENG: "Science"},

    FACULTIES: {
        titleUA: "Факультети",
        titleENG: "Faculties",
        subLinks: [
            {
                to: "/page/faculty1",
                name: "faculty1",
                titleUA: "Факультет телекомунікаційних систем",
                titleENG: "Faculty of Telecommunication Systems"
            },
            {
                to: "/page/faculty2",
                name: "faculty2",
                titleUA: "Факультет інформаційних технологій",
                titleENG: "Faculty of Information Technologies"
            },
            {
                to: "/page/faculty3",
                name: "faculty3",
                titleUA: "Факультет бойового застосування систем управління та звʼязку",
                titleENG: "Faculty of the combat usage of control  and communications systems"
            },
        ] as SubLink[]
    },
    DEPARTMENTS: {
        titleUA: "Кафедри",
        titleENG: "Departments",
        subLinks: [
            {
                to: "/page/department1",
                name: "department1",
                titleUA: "Кафедра загально-військових дисциплін ",
                titleENG: "Department of Combined Arms Disciplines"
            },
            {
                to: "/page/department2",
                name: "department2",
                titleUA: "Кафедра іноземних мов ",
                titleENG: "Department of Foreign Languages"
            },
            {
                to: "/page/department3",
                name: "department3",
                titleUA: "Кафедра фізичного виховання, спеціальної фізичної підготовки і спорту ",
                titleENG: "Department of Physical Education, Special Physical training and Sports"
            },
            {
                to: "/page/department4",
                name: "department4",
                titleUA: "Кафедра військово-гуманітарних дисциплін ",
                titleENG: "Department of Military and Humanitarian Disciplines"
            },
            {
                to: "/page/department5",
                name: "department5",
                titleUA: "Кафедра математики та фізики ",
                titleENG: "Department Foreign Languages"
            },
            {
                to: "/page/department6",
                name: "department6",
                titleUA: "Кафедра військової підготовки ",
                titleENG: "Department of Military Training"
            },
        ] as SubLink[]
    },
    UNITS: {
        titleUA: "Підрозділи",
        titleENG: "Units",
        subLinks: [
            {
                to: "/page/unit1",
                name: "unit1",
                titleUA: "Науковий центр зв’язку та інформатизації",
                titleENG: "Science center of signal and information technologies"
            },
            {
                to: "/page/unit2",
                name: "unit2",
                titleUA: "Батальйон забезпечення навчального процесу",
                titleENG: "Supplement Battalion of studying courses"
            },
            {
                to: "/page/unit3",
                name: "unit3",
                titleUA: "5-ті Курси перепідготовки та підвищення кваліфікації",
                titleENG: "5th Courses of retraining and advanced training"
            },
            {
                to: "/page/mcsp",
                name: "mcsp",
                titleUA: "Військовий коледж сержантського складу",
                titleENG: "Military college of sergeants personnel"
            },
        ] as SubLink[]
    },
    COOPERATION: {
        titleUA: "Співробітництво",
        titleENG: "Cooperation",
        subLinks: [
            {
                to: "/page/cooperation_international",
                name: "cooperation_international",
                titleUA: "Міжнародне співробітництво",
                titleENG: "International Cooperation"
            },
            {
                to: "/page/cooperation-inside",
                name: "cooperation_inside",
                titleUA: "Внутрішнє співробітництво",
                titleENG: "Inside Cooperation"
            },
        ] as SubLink[]
    },


    OUR_WINS: {to: "/page/wins", name: "our_wins", titleUA: "Наші перемоги", titleENG: "Our Wins"},

    SUPERIORS: {to: "/page/superiors", name: "superiors", titleUA: "Керівний склад", titleENG: "Superiors"},
    WHY_MITIT: {to: "/page/why", name: "why", titleUA: "Чому ВІТІ?", titleENG: "Why MITIT?"},
    HISTORY: {to: "/page/history", name: "history", titleUA: "Історична довідка", titleENG: "History"},
    PUBLIC_INFO: {to: "/page/public", name: "public", titleUA: "Публічна інформація", titleENG: "Public Info"},
    HEROES: {to: "/page/heroes", name: "heroes", titleUA: "Наші Герої", titleENG: "Heroes"},
    GENDER_POLICY: {to: "/page/gender-policy", name: "gender-policy", titleUA: "Гендерна політика", titleENG: "Gender policy"},



    //* FOOTER LINKS *//
    EDUCATIONAL_ACTIVITIES: {to: "/page/study", name: "educational_activity", titleUA: "Організація освітньої діяльності", titleENG: "Organization of educational activities"},
    METHODICAL_WORK: {to: "/page/study", name: "methodical_work", titleUA: "Методична робота", titleENG: "Methodical work"},
    MATERIAL_AND_TECHNICAL_BASE: {to: "/page/study", name: "material_and_technical_base", titleUA: "Матеріально-технічна база", titleENG: "Material and technical base"},
    LICENSE_CERTIFICATIONS_ACCREDITATION: {to: "/page/study", name: "license_certifications_accreditation", titleUA: "Ліцензія, сертифікати, акредитація", titleENG: "License, certifications, accreditation"},
    REGULATORY_FRAMEWORK: {to: "/page/study", name: "regulatory_framework", titleUA: "Нормативно-правова база", titleENG: "Regulatory framework"},

    FULL_TIME_EDUCATION: {to: "/page/entrants", name: "full-time_education", titleUA: "Денна форма навчання", titleENG: "Full-time education"},
    EXTRAMURAL_STUDIES: {to: "/page/entrants", name: "extramural_studies", titleUA: "Заочна форма навчання", titleENG: "Extramural studies"},
    DOCTORAL_STUDIES_ADJUNCT_STUDIES: {to: "/page/entrants", name: "doctoral_studies_adjunct_studies", titleUA: "Навчання в докторантурі та ад'юнктурі", titleENG: "Training in doctoral studies and adjuncts"},
    PROGRAM_OF_RESERVE_OFFICERS: {to: "/page/entrants", name: "program_of_reserve_officers", titleUA: "Підготовка за програмою офіцерів запасу", titleENG: "Reserve Officers Training"},
    RECEPTION: {to: "/page/entrants", name: "reception", titleUA: "Приймальна комісія", titleENG: "Admission committee"},
    EXAM_2020: {to: "/page/entrants", name: "exam_2020", titleUA: "ЗНО 2020", titleENG: "Exam 2020"},

    RECEPTION_OF_CITIZENS: {to: "/page/contacts", name: "reception_of_citizens", titleUA: "Прийом громадян", titleENG: "Reception of citizens"},
    ADDRESS: {to: "/page/contacts", name: "address", titleUA: "Адреса", titleENG: "Address"},
    HOTLINE: {to: "/page/contacts", name: "hotline", titleUA: "Гаряча лінія", titleENG: "Hotline"},
}
