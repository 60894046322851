import {RouterNames} from "./RouterNames";
import {NavLink} from "widgets/WrapperParts/Header/model/types";


export const instituteName = {
    ua: "ВІЙСЬКОВИЙ ІНСТИТУТ<br>ТЕЛЕКОМУНІКАЦІЙ ТА ІНФОРМАТИЗАЦІЇ<br>ІМЕНІ ГЕРОЇВ КРУТ",
    eng: "Kruty Heroes<br> Military Institute of Telecommunications<br> and Information Technology"
}

export const middleName = {
    ua: "Вищий військовий навчальний заклад України, що готує офіцерів військ зв'язку для усіх<br> силових структур України (ЗСУ, СБУ, ДПСУ, МВС, МНС, СЗР).",
    eng: "The Higher Military Educational Institution of Ukraine, which Trains Liaison Officers for<br> All power structures of Ukraine (AFU, SBU, SBGS, Ministry of Internal Affairs, Ministry of Emergencies, SVR)."
}

export const upperTitle = "<a href='https://t.me/mitit_assistant_bot'>Telegram канал</a> - Вступ до ВІТІ 2024"


export const links = {
    linksT: [
        RouterNames.INSTITUTE,
        RouterNames.ENTRANTS,
        RouterNames.STUDY,
        RouterNames.CONTACTS
    ] as NavLink[],
    linksB: [
        RouterNames.NEWS,
        RouterNames.SCIENCE,
        RouterNames.FACULTIES,
        RouterNames.DEPARTMENTS,
        RouterNames.UNITS,
        RouterNames.COOPERATION,
        RouterNames.OUR_WINS
    ] as NavLink[]
}
export const socialIcons = [
    {iconBootstrap: "bi bi-instagram", height: 25, width: 25, to: "https://www.instagram.com/viti_krut/"},
    {iconBootstrap: "bi bi-telegram", height: 25, width: 25, to: "https://t.me/+8801LI0j47FmNTFi"},
    {iconBootstrap: "bi bi-facebook", height: 25, width: 25, to: "https://www.facebook.com/viti.edu.ua/"},
    {iconBootstrap: "bi bi-youtube", height: 25, width: 25, to: "https://www.youtube.com/@user-yq1xr5sl6v"},
    {iconBootstrap: "bi bi-tiktok", height: 25, width: 25, to: "https://www.tiktok.com/@viti_krut?_t=8ZUHTA4Hyis"}
]


