import {action, makeAutoObservable, observable} from "mobx";
import {SearchData} from "../model";
import $api from "shared/api";
import f from "compose-function";

class SearchStore {
    @observable isLoading: boolean = true;
    @observable titleImageIsLoading: boolean = true;
    @observable searchData: SearchData = {} as SearchData;

    constructor() {
        makeAutoObservable(this);
    }

    applyData(data: SearchData) {
        this.searchData = data;
    }

    notEmpty() : boolean {
        if (this.searchData.news || this.searchData.pages){
            if (this.searchData.news?.items?.length > 0)
                return true
            else return this.searchData.pages?.items?.length > 0;
        }
        return false
    }

    @action
    async searchContent(content: string) {
        try {
            this.isLoading = true
            await this.loadContent(content);
            this.isLoading = false
        } catch (error) {
            console.error(error);
        }
    }

    @action
    async loadContent(content: string) {
        try {
            const response = await $api.get(`/search`, {
                params: { content: content },
            });
            this.applyData(response.data)
        } catch (error) {
            console.error(error);
        }
    }
}

const searchStore = new SearchStore();
export default searchStore;
