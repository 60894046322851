import React, {FC, useState} from 'react';
import css from "./SearchBlock.module.scss"
import {observer} from "mobx-react";
import Icon from "../Icon/Icon";
import searchStore from "../../../entities/search/store/SearchStore";
import {SearchData} from "../../../entities/search";
import SearchResultBlock from "../../../features/SearchResultBlock/SearchResultBlock";


interface SearchMobileProps {
    isOpen: boolean;
    close: () => void;
}

const SearchMobile: FC<SearchMobileProps> = observer(({isOpen, close}) => {

    const [searchContent, setSearchContent] = useState("")
    const [rerender, setRerender] = useState(false)
    const [status, setStatus] = useState("")

    const forceRerender = () => setRerender(!rerender)


    const search = (text: string) => {

        setSearchContent(text)
        searchStore.applyData({} as SearchData)

        if (text.length <= 3) {
            setStatus("Жодних записів не знайдено :(")
            forceRerender()
            return
        }
        setStatus("Шукаємо...")
        forceRerender()
        searchStore.searchContent(text)
            .then(() => {
                if (!searchStore.notEmpty()) {
                    setStatus("Жодних записів не знайдено :(")
                }
            })
            .catch((e) => {console.log(e)})
    }

    return (
        <div className={`${css.searchContainerMobile} ${isOpen && css.searchMobile_active}`}>
            <div className={`${css.searchMobile_close} ${isOpen && css.close_searchMobile_active}`} onClick={() => {
                close()
            }}></div>
            <div className={`${css.searchMainMobile} ${isOpen && css.searchMainMobile_active}`}>
                <div className={`${css.container}`}>
                    <input onChange={(e) => {
                        search(e.target.value)
                    }}
                           placeholder={"Пошук..."}
                           className={`${css.input}`}
                           type={"text"}/>
                    <Icon  style={{color: "black"}} className={`${css.search}`}
                          height={100}
                          width={50}
                          iconBootstrap={"bi bi-search"}/>
                </div>
                {searchStore.searchData && searchStore.notEmpty() ?
                    <div className={css.searchBlock}>
                        {searchStore.searchData?.news?.items?.length > 0 ?
                            <div className={css.resultContainer}>
                                <h4>
                                    Новини:
                                </h4>
                                <div style={{display: "flex", flexDirection: "column", gap:"15px"}}>
                                    {searchStore.searchData.news?.items?.map((value, index) => (
                                        <SearchResultBlock key={`ResultSearch-${value.id}-${index}`} index={index}
                                                           value={value} type={"news"} close={close}/>
                                    ))}
                                </div>
                            </div>
                            :
                            null
                        }
                        {searchStore.searchData?.pages?.items?.length > 0 ?
                            <div className={css.resultContainer}>
                                <h4>
                                    Сторінки:
                                </h4>
                                <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                                    {searchStore.searchData.pages?.items?.map((value, index) => (
                                        <SearchResultBlock key={`ResultSearch-${value.id}-${index}`} index={index}
                                                           value={value} type={"pages"} close={close}/>
                                    ))}
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    :
                    searchContent &&  searchContent.length > 0 ?
                        <div className={css.searchBlock}>
                            <div className={css.resultContainer}>
                                <span>{status}</span>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </div>
    );
});

export default SearchMobile;