import React, {FC} from 'react';
import css from "./MiddleTitle.module.scss";
import {languageToggleStore} from "features/LanguageToggle/model/languageToggleStore";
import {observer} from "mobx-react";
import HTMLReactParser from "html-react-parser";
import {instituteName, middleName} from "shared/enums/Data";


interface MiddleTitleProps {
    value: string;
}

const MiddleTitle: FC<MiddleTitleProps> = observer(({value}) => {
    const {activeLanguage} = languageToggleStore

    return (
        <div className={css.middleTitle}>
            {HTMLReactParser(activeLanguage === "ua" ? middleName.ua : middleName.eng)}
        </div>
    );
});

export default MiddleTitle;