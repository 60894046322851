import React, {useEffect, useState} from 'react';
import css from "./LayoutHeaderMobile.module.scss"
import TitleLogo from "shared/ui/TitleLogo/TitleLogo";
import Sidebar from "./ui/Sidebar/Sidebar";
import {instituteName} from "shared/enums/Data";
import {Link} from "react-router-dom";
import {RouterNames} from "shared/enums/RouterNames";
import SearchMobile from "shared/ui/SearchBlock/SearchBlock";

const LayoutHeaderMobile = () => {
    const [topPosition, setTopPosition] = useState(30)
    const [rightPosition, setRightPosition] = useState(-5)
    const [sidebar, setSidebar] = useState(false)
    const [search, setSearch] = useState(false)

    const closeSearch = () => {
        setSearch(false)
        // @ts-ignore
        document.getElementById("root").style.overflow = "visible"
    }

    const closeSidebar = () => {
        setSidebar(false)
        // @ts-ignore
        document.getElementById("root").style.overflow = "visible"
    }

    const openSearch = () => {
        setSearch(true)
        // @ts-ignore
        document.getElementById("root").style.overflow = "hidden"

    }

    const openSidebar = () => {
        setSidebar(true)
        // @ts-ignore
        document.getElementById("root").style.overflow = "hidden"

    }

    useEffect(() => {
        let prevScrollY = window.scrollY;

        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (window.scrollY < 30) {
                setRightPosition(-5)
            } else {
                currentScrollY > prevScrollY ? setRightPosition(-100) : setRightPosition(-5)
            }
            prevScrollY = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let prevScrollY = window.scrollY;

        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (window.scrollY < 30) {
                setTopPosition(30)
            } else {
                currentScrollY > prevScrollY ? setTopPosition(-100) : setTopPosition(30)
            }
            prevScrollY = currentScrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div style={{top: `${topPosition}px`}} className={css.mobileHeader}>
                <Link to={RouterNames.HOME.to} className={css.logoContainer}>
                    <TitleLogo logoSize={"10vh"}/>
                </Link>
                <button className={css.openSidebar} onClick={openSidebar}>
                    <i className="bi bi-list"></i>
                </button>
            </div>
            <div className={css.openSearchBlock}>
                <button style={{right: `${rightPosition}px`}} className={css.openSearch} onClick={openSearch}>
                    <i className="bi bi-search"></i>
                </button>
            </div>
            <SearchMobile isOpen={search} close={closeSearch}/>

            <Sidebar isOpen={sidebar} close={closeSidebar}/>
        </>
    );
};

export default LayoutHeaderMobile;