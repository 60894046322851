import React, {FC, useRef, useState} from 'react';
import Icon from "shared/ui/Icon/Icon";
import css from "./Search.module.scss";
import searchStore from "../../../../../entities/search/store/SearchStore";
import SearchResultBlock from "features/SearchResultBlock/SearchResultBlock";
import {SearchData} from "../../../../../entities/search";
import {observer} from "mobx-react";


interface SearchProps {
    color?: string;
    mobile?: boolean;
}

const Search: FC<SearchProps> = observer(({color, mobile}) => {

    const [searchContent, setSearchContent] = useState("")
    const [rerender, setRerender] = useState(false)
    const [show, setShow] = useState(false)
    const [status, setStatus] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const input = useRef(null)

    const forceRerender = () => setRerender(!rerender)


    const openSearch = () => {
        if (!isOpen) {
            setIsOpen(!isOpen)
            setShow(!show)
            // @ts-ignore
            input.current.focus()
        } else {
            setIsOpen(!isOpen)
            setShow(!show)
        }
    }

    const closeSearch = () => {
        setTimeout(() => {
            setIsOpen(!isOpen)
            setShow(!show)
        }, 300)
    }

    const search = (text: string) => {
        setSearchContent(text)
        searchStore.applyData({} as SearchData)

        if (text.length <= 3) {
            setStatus("Жодних записів не знайдено :(")
            forceRerender()
            return
        }
        setStatus("Шукаємо...")
        forceRerender()
        searchStore.searchContent(text)
            .then(() => {
                if (!searchStore.notEmpty()) {
                    setStatus("Жодних записів не знайдено :(")
                }
            })
            .catch((e) => {console.log(e)})
    }

    return (
        <>
            <div className={`${css.container}  ${isOpen && css.containerActive} ${mobile && css.containerMobile}`}>
                <input onChange={(e) => {
                    search(e.target.value)
                }}
                       ref={input}
                       onBlurCapture={closeSearch}
                       placeholder={"Пошук..."}
                       className={`${css.input} ${isOpen && css.inputActive} ${mobile && css.inputMobile}`}
                       type={"text"}/>
                <Icon onClick={openSearch} style={{color: color}} className={`${css.search} ${css.searchMobile}`}
                      height={100}
                      width={50}
                      iconBootstrap={"bi bi-search"}/>
            </div>
            {show?
                searchStore.searchData && searchStore.notEmpty() ?
                    <div className={css.searchBlock}>
                        {searchStore.searchData?.news?.items?.length > 0 ?
                            <div className={css.resultContainer}>
                                <h4>
                                    Новини:
                                </h4>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    {searchStore.searchData.news?.items?.map((value, index) => (
                                        <SearchResultBlock key={`ResultSearch-${value.id}-${index}`} index={index}
                                                           value={value} type={"news"}/>
                                    ))}
                                </div>
                            </div>
                            :
                            null
                        }
                        {searchStore.searchData?.pages?.items?.length > 0 ?
                            <div className={css.resultContainer}>
                                <h4>
                                    Сторінки:
                                </h4>
                                <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                                    {searchStore.searchData.pages?.items?.map((value, index) => (
                                        <SearchResultBlock key={`ResultSearch-${value.id}-${index}`} index={index}
                                                           value={value} type={"pages"}/>
                                    ))}
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                :
                searchContent &&  searchContent.length > 0 ?
                    <div className={css.searchBlock}>
                        <div className={css.resultContainer}>
                            <span>{status}</span>
                        </div>
                    </div>
                    :
                    null
                :
                null
            }
        </>
    );
});

export default Search;