import React, {FC} from 'react';
import css from "./YouTubeVideo.module.css"

interface YouTubeVideoProps {
    videos: string[];
    width?: number;
    height?: number;
}

const YouTubeVideos: FC<YouTubeVideoProps> = ({videos, width, height}) => {

    if (videos.length === 1) {
        return (
            <div className={css.containerOne}>
                {
                    <iframe className={css.video} src={videos[0]}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                }
            </div>
        );
    } else if (videos.length > 1) {
        return (
            <div className={css.containerMany}>
                {videos.map(src => (
                    <iframe className={css.video} src={src}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                ))

                }
            </div>
        );
    } else {
        return (
            <div className={css.emptyContainer}>Додайте відео</div>
        )
    }

};

export default YouTubeVideos;