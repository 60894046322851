import React, {useEffect} from "react";
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import {RouterNames} from "shared/enums/RouterNames";
import InstitutePage from "./Institute/InstitutePage";
import HomePage from "./Home/HomePage";
import NewsPage from "./News/NewsPage";
import UniversalPage from "./UniversalPage/UniversalPage";
import NewsDetailedPage from "./NewsDetailed/NewsDetailedPage";
import WinsPage from "./Wins/WinsPage";
import NotFoundPage from "./NotFound/NotFoundPage";
import newsStore from "../entities/news/store/news-page-store";


export const Routing = () => {
    const location = useLocation()
    useEffect(() => {
        if (!location.pathname.includes('/news')) {
            newsStore.page = 0
        }
    }, [location])

    return (
        <Routes>
            <Route path={RouterNames.HOME.to} element={<HomePage/>}/>
            <Route path={RouterNames.INSTITUTE.to} element={<InstitutePage/>}/>
            <Route path={RouterNames.NEWS.to} element={<NewsPage/>}/>
            <Route path={RouterNames.NEWS.to + "/:id"} element={<NewsDetailedPage/>}/>
            <Route path={RouterNames.OUR_WINS.to} element={<WinsPage/>}/>

            <Route path={"/page/:name"} element={<UniversalPage/>}/>
            <Route path={RouterNames.NOT_FOUND.to} element={<NotFoundPage/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};

