import React, {FC} from 'react';
import css from "./MiddleTitle.module.scss";
import {languageToggleStore} from "features/LanguageToggle/model/languageToggleStore";
import HTMLReactParser from "html-react-parser";
import {middleName} from "shared/enums/Data";
import {observer} from "mobx-react";


const MiddleTitle: FC = observer(() => {
    const {activeLanguage} = languageToggleStore
    return (
        <div className={css.middleTitle}>
            {HTMLReactParser(activeLanguage === "ua" ? middleName.ua : middleName.eng)}
        </div>
    );
});

export default MiddleTitle;